import styled from "styled-components";

export const HeroWrapper = styled.div`
    background-color: #F3F7FA;
    overflow: hidden;
`;

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 3rem auto;
    width: 100%;
`;

export const HeroTitle = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
        font-size: 2.75rem;
        line-height: 1.2;
    }

    & > p {
        font-size: 1rem;
        line-height: 1.5;
        margin: 1.5rem 2% 0;
    }

    @media screen and (max-width: 720px) {
        & >img {
            width: 100%;
        }
    }

    @media screen and (min-width: 1024px) {
        width: 950px;

        & > h1 {
            font-size: 4.5rem;;
        }

        & > p {
            font-size: 1.3rem;
            margin: 1.5rem 8% 0;
        }
    }
`;

export const Partnership = styled.div`
    margin: 1.5rem 0 0;

    & > p {
        font-size: 15px;
        margin-bottom: 1.5rem;
        font-weight: 500;
    }
    
`;

export const PartnershipCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h2 {
        font-size: 17px;
        margin: 1rem 0 8px
    }
    & > span {
        font-size: 14px;
        font-weight: 500;
    }

    & > img {
        width: 150px;
        /* filter: brightness(100%) contrast(0%) saturate(100%) blur(0px) hue-rotate(0deg); */
        cursor: pointer;
        transition: 0.8s ease;

        &:hover {
            /* filter: none; */
            transform: scale(1.1);
        }
    }
`;

export const HeroCTAWrapper = styled.div`
    margin: 32px 0;
`;

export const HeroCTA = styled.a`
    text-decoration: none;
    padding: 16px 24px;
    color: inherit;
    border: 1px solid #14123B;
    font-size: 16px;
    font-weight: 500;
    background-color: #5235FF00;
    border-radius: 200px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0) 0px 0px, rgb(0, 0, 0) 2px 3px 0px 0px !important;
    border: 2px solid #000;
    outline: none;
`;