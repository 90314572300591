import React from "react";
import { useTranslation } from "react-i18next";
import * as Md from 'react-icons/md';
import logo from '../../assets/image/logo_head.png';
import { SectionContainer, SectionWrapper } from "../../pages/home/style";
import { FooterBottom, FooterBottomLeft, FooterBottomMenu, FooterButton, FooterContainer, FooterTop, FooterWrapper } from "./style";

export const Footer = () => {
    const { t } = useTranslation();
    return (
        <FooterWrapper id="contacts">
            <SectionWrapper style={{ marginBottom: "0" }}>
                <SectionContainer>
                    <FooterContainer>
                        <FooterTop>
                            <h1>{t('footer.headline')}</h1>
                            <FooterButton href="mailto:inquiry@kcpaicloud.com?subject=Hi, I'm interested!" target="_blank">
                                {t('footer.button')} <Md.MdOutlineArrowOutward />
                            </FooterButton>
                        </FooterTop>
                        <FooterBottom>
                            <FooterBottomLeft>
                                <img src={logo} alt="logo" />
                                <h1>PT Kharisma Cakrawala Persada</h1>
                                <span>©2024 PT Kharisma Cakrawala Persada.</span>
                            </FooterBottomLeft>
                            <FooterBottomMenu>
                                <h1>PT Kharisma Cakrawala Persada</h1>
                                <p>Cyber 2 Tower, 28th Floor,</p>
                                <p>Jl. HR. Rasuna Said Blok X-5 No.13</p>
                                <p>Kuningan Timur, Setiabudi</p>
                                <p>Jakarta Selatan 12950</p>
                            </FooterBottomMenu>
                        </FooterBottom>
                    </FooterContainer>
                </SectionContainer>
            </SectionWrapper>
        </FooterWrapper>
    )
}