import styled from "styled-components";

export const NavbarWrapper = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 0 rgba(5, 122, 255, 0.06);
    z-index: 999;
`;

export const NavbarContainer = styled.div`
    padding: 8px;
    margin: 0 auto;
    width: 100%;
    max-width: 1180px;

    @media screen and (min-width: 1024px) {
        padding: 1rem 0;
    }
`;

export const NavbarFlex = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > a {
        & > img {
            width: 130px;
        }
    }
`;

export const LanguageFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;


    & > select {
        border: 0;
        font-size: 17px;
        font-weight: 500;
        font-family: 'Circular Std', sans-serif;
        background: transparent;
        color: #333;

        &:active, &:focus {
            outline: none;
        }
    }
`;

export const NavbarMenu = styled.div`
    display: none;
    gap: 2rem;

    & > a {
        text-decoration: none;
        font-size: 15px;
        font-weight: 500;
        color: inherit;
    }

    @media screen and (min-width: 1024px) {
        display: flex;
    }
`;

export const NavbarCTA = styled.a`
    text-decoration: none;
    color: #fff!important;
    display: none;
    outline: none;
    border: 0;
    padding: 12px 18px;
    border-radius: 10px;
    background-color: #2a45cf;
    font-size: 14px;
    cursor: pointer;

    @media screen and (min-width: 1024px) {
        display: block;
    }
`;

export const MobileLanguageWrapper = styled.div`
    display: block;

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;