import styled from "styled-components";

export const FooterWrapper = styled.footer`
    background-color: #F3F7FA;
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FooterTop = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 2rem 0 4rem;
    border-bottom: 1px solid #E6E6E4;
    
    & > h1 {
        line-height: 1.28em;
        letter-spacing: -.5px;
        font-weight: 500;
        margin-bottom: 2rem;
    }


    @media screen and (min-width: 1024px) {
        flex-direction: row;

        & > h1 {
            margin-bottom: 0;
        }
    }
`;

export const FooterButton = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #2a45cf;
    padding: 12px 16px;
    border-radius: 20px;
    outline: none;
    border: 0;
    color: #fff;
`;

export const FooterBottom = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    flex-direction: column-reverse;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

export const FooterBottomLeft = styled.div`
    display: flex;
    flex-direction: column;

    & > h1 {
        font-size: 18px;
        margin: 12px 0 4px;
    }

    & > img { 
        width: 75px;
    }

    & > span {
        font-size: 14px;

        & >a {
            text-decoration: none;
            color: inherit;
            font-weight: 500;
            font-family: 'Circular Std', sans-serif;
        }
    }
`;

export const FooterBottomMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: right;
    margin-bottom: 2rem;

    & > h1 {
        font-size: 18px;
    }

    @media screen and (min-width: 1024px) {
        margin-bottom: 0;
    }
`;