import styled from "styled-components";

export const ServiceWrapper = styled.section`
    /* background-color: #F3F7FA; */
    padding: 2rem 0;
    overflow: hidden;
`;

export const DividerWrapper = styled.div`
    margin: 0 auto 2rem;

    & > img {
        width: 150px;
    }
`;

export const LineDivider = styled.div`
    height: 1px;
    width: 25%;
    border: 1px solid #ddd;
    margin-bottom: 2rem;
`;

export const ServiceHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 0 5%; */
    text-align: center;

    & > h1 {
        text-transform: uppercase;
        color: #2a45cf;
        font-size: 1.25rem;
        font-weight: 700;
        letter-spacing: 2px;
    }

    & > h2 {
        margin-top: 1.5rem;
        font-size: 2rem;
    }

    & > p {
        font-size: 16px;
        line-height: 1.4;
        margin: 1rem 2%;
    }
    
    @media screen and (min-width: 1024px) {
        margin: 0 15%;
        & > h2 {
            font-size: 2.5rem;
        }

        & > p {
            font-size: 18px;
            margin: 1rem 10%;
        }
    }
`

export const ServiceGrid = styled.div`
    display: grid;
    gap: 2rem;
    margin-top: 1.5rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`;

export const ServiceCard = styled.div`
    padding: 2.5rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
    /* border: 2px solid #000; */
    border-radius: 20px;
    cursor: pointer;
    transition: .5s ease;
    background-color: white;

    &:hover {
        transform: scale(1.02);
    }

    & > img { 
        width: 75px;
        margin-bottom: 1rem;
    }

    & > h1 {
        font-size: 21px;
        font-weight: 500;
    }

    & > p {
        line-height: 1.5;
        margin-top: 1rem;
        font-size: 17px;
    }
`;