import React from "react";
import { useTranslation } from "react-i18next";
import * as Md from 'react-icons/md';
import pc from '../../assets/image/hero.png';
import lintasarta from '../../assets/image/lintasarta.png';
import { SectionContainer, SectionWrapper } from "../../pages/home/style";
import { HeroCTA, HeroCTAWrapper, HeroContainer, HeroTitle, HeroWrapper, Partnership, PartnershipCard } from "./style";

export const Hero = () => {
    const { t } = useTranslation();

    return (
        <HeroWrapper>
            <SectionWrapper>
                <SectionContainer>
                    <HeroContainer>
                        <HeroTitle >
                            <h1 data-aos="fade-in">{t('hero.title')}</h1>
                            <p data-aos="fade-in">{t('hero.subtitle')}</p>
                            <HeroCTAWrapper data-aos="fade-in">
                                <HeroCTA href="mailto:inquiry@kcpaicloud.com?subject=Hi, I'm interested!" target="_blank">
                                    <span>{t('hero.button')}</span>
                                    <Md.MdOutlineArrowOutward size={20} />
                                </HeroCTA>
                            </HeroCTAWrapper>
                            <img data-aos="fade-in" src={pc} alt="pc" />
                            <Partnership data-aos="fade-in">
                                <p>{t('hero.partner')}</p>
                                <PartnershipCard>
                                    <img src={lintasarta} alt="lintasarta" />
                                    <h2>Lintasarta</h2>
                                    <span>({t('hero.partnerdesc')})</span>
                                </PartnershipCard>
                            </Partnership>
                        </HeroTitle>
                    </HeroContainer>
                </SectionContainer>
            </SectionWrapper>
        </HeroWrapper>
    )
}