import styled from "styled-components";

export const SpecialWrapper = styled.section`
    margin: 8rem 0 -6.5rem;
`;

export const SpecialContainer = styled.div`
    background-color: #2a45cf;;
    padding: 4px 10px;
    transform: rotateZ(-2.07deg) translateY(-42%);
    font-size: 2.5rem;
    color: #fff;
    font-family: 'Circular Std', sans-serif;

    @media screen and (min-width: 1024px) {
        font-size: 5rem;
    }
`;