import React from "react";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import { SpecialContainer, SpecialWrapper } from "./style";

export const Special = () => {
    const { t } = useTranslation();
    return (
        <SpecialWrapper>
            <SpecialContainer>
                <Marquee speed={80}>{t('banner')}</Marquee>
            </SpecialContainer>
        </SpecialWrapper>
    )
}