import React from "react";
import { useTranslation } from "react-i18next";
import cost from '../../assets/image/services/gpu.png';
import monitoring from '../../assets/image/services/monitoring.png';
import performance from '../../assets/image/services/performance.png';
import scalability from '../../assets/image/services/scalability.png';
import security from '../../assets/image/services/security.png';
import streamlined from '../../assets/image/services/streamlined.png';
import { SectionContainer, SectionWrapper } from "../../pages/home/style";
import { ServiceCard, ServiceGrid, ServiceHeader, ServiceWrapper } from "./style";

export const Services = () => {
    const { t } = useTranslation();

    return (
        <ServiceWrapper id="services">
            <SectionWrapper style={{ marginTop: "2rem" }}>
                <SectionContainer>
                    <ServiceHeader data-aos="fade-down">
                        {/* <DividerWrapper>
                            <img src={divider} alt="divider" />
                        </DividerWrapper> */}
                        <h1>{t('services.title')}</h1>
                        <h2>{t('services.headline')}</h2>
                        <p>{t('services.subheadline')}</p>
                    </ServiceHeader>
                    <ServiceGrid>
                        <ServiceCard data-aos="fade-right">
                            <img src={cost} alt="cost" />
                            <h1>{t('services.items.gpu.title')}</h1>
                            <p>{t('services.items.gpu.description')}</p>
                        </ServiceCard>
                        <ServiceCard data-aos="fade-down">
                            <img src={security} alt="security" />
                            <h1>{t('services.items.security.title')}</h1>
                            <p>{t('services.items.security.description')}</p>
                        </ServiceCard>
                        <ServiceCard data-aos="fade-left">
                            <img src={streamlined} alt="streamlined" />
                            <h1>{t('services.items.solutions.title')}</h1>
                            <p>{t('services.items.solutions.description')}</p>
                        </ServiceCard>
                        <ServiceCard data-aos="fade-right">
                            <img src={performance} alt="performance" />
                            <h1>{t('services.items.performance.title')}</h1>
                            <p>{t('services.items.performance.description')}</p>
                        </ServiceCard>
                        <ServiceCard data-aos="fade-up">
                            <img src={scalability} alt="scalability" />
                            <h1>{t('services.items.scalability.title')}</h1>
                            <p>{t('services.items.scalability.description')}</p>
                        </ServiceCard>
                        <ServiceCard data-aos="fade-left">
                            <img src={monitoring} alt="monitoring" />
                            <h1>{t('services.items.support.title')}</h1>
                            <p>{t('services.items.support.description')}</p>
                        </ServiceCard>
                    </ServiceGrid>
                </SectionContainer>
            </SectionWrapper>
        </ServiceWrapper>
    )
}