export const industryOptions = [
    { value: "ACCOUNTING", text: "Accounting" },
    { value: "AIRLINES_AVIATION", text: "Airlines/Aviation" },
    { value: "ALTERNATIVE_DISPUTE_RESOLUTION", text: "Alternative Dispute Resolution" },
    { value: "ALTERNATIVE_MEDICINE", text: "Alternative Medicine" },
    { value: "ANIMATION", text: "Animation" },
    { value: "APPAREL_FASHION", text: "Apparel & Fashion" },
    { value: "ARCHITECTURE_PLANNING", text: "Architecture & Planning" },
    { value: "ARTS_AND_CRAFTS", text: "Arts and Crafts" },
    { value: "AUTOMOTIVE", text: "Automotive" },
    { value: "AVIATION_AEROSPACE", text: "Aviation & Aerospace" },
    { value: "BANKING", text: "Banking" },
    { value: "BIOTECHNOLOGY", text: "Biotechnology" },
    { value: "BROADCAST_MEDIA", text: "Broadcast Media" },
    { value: "BUILDING_MATERIALS", text: "Building Materials" },
    { value: "BUSINESS_SUPPLIES_AND_EQUIPMENT", text: "Business Supplies and Equipment" },
    { value: "CAPITAL_MARKETS", text: "Capital Markets" },
    { value: "CHEMICALS", text: "Chemicals" },
    { value: "CIVIC_SOCIAL_ORGANIZATION", text: "Civic & Social Organization" },
    { value: "CIVIL_ENGINEERING", text: "Civil Engineering" },
    { value: "Cloud Compute Reseller", text: "Cloud Compute Reseller" },
    { value: "Cloud Platform Provider", text: "Cloud Platform Provider" },
    { value: "COMMERCIAL_REAL_ESTATE", text: "Commercial Real Estate" },
    { value: "COMPUTER_NETWORK_SECURITY", text: "Computer & Network Security" },
    { value: "COMPUTER_GAMES", text: "Computer Games" },
    { value: "COMPUTER_HARDWARE", text: "Computer Hardware" },
    { value: "COMPUTER_NETWORKING", text: "Computer Networking" },
    { value: "COMPUTER_SOFTWARE", text: "Computer Software" },
    { value: "CONSTRUCTION", text: "Construction" },
    { value: "CONSUMER_ELECTRONICS", text: "Consumer Electronics" },
    { value: "CONSUMER_GOODS", text: "Consumer Goods" },
    { value: "CONSUMER_SERVICES", text: "Consumer Services" },
    { value: "COSMETICS", text: "Cosmetics" },
    { value: "DAIRY", text: "Dairy" },
    { value: "DEFENSE_SPACE", text: "Defense & Space" },
    { value: "DESIGN", text: "Design" },
    { value: "E_LEARNING", text: "E-Learning" },
    { value: "EDUCATION_MANAGEMENT", text: "Education Management" },
    { value: "ELECTRICAL_ELECTRONIC_MANUFACTURING", text: "Electrical/Electronic Manufacturing" },
    { value: "ENTERTAINMENT", text: "Entertainment" },
    { value: "ENVIRONMENTAL_SERVICES", text: "Environmental Services" },
    { value: "EVENTS_SERVICES", text: "Events Services" },
    { value: "EXECUTIVE_OFFICE", text: "Executive Office" },
    { value: "FACILITIES_SERVICES", text: "Facilities Services" },
    { value: "FARMING", text: "Farming" },
    { value: "FINANCIAL_SERVICES", text: "Financial Services" },
    { value: "FINE_ART", text: "Fine Art" },
    { value: "FISHERY", text: "Fishery" },
    { value: "FOOD_BEVERAGES", text: "Food & Beverages" },
    { value: "FOOD_PRODUCTION", text: "Food Production" },
    { value: "FUND_RAISING", text: "Fund-Raising" },
    { value: "FURNITURE", text: "Furniture" },
    { value: "GAMBLING_CASINOS", text: "Gambling & Casinos" },
    { value: "Generative AI Tool", text: "Generative AI Tools" },
    { value: "GLASS_CERAMICS_CONCRETE", text: "Glass, Ceramics & Concrete" },
    { value: "GOVERNMENT_ADMINISTRATION", text: "Government Administration" },
    { value: "GOVERNMENT_RELATIONS", text: "Government Relations" },
    { value: "GRAPHIC_DESIGN", text: "Graphic Design" },
    { value: "HEALTH_WELLNESS_AND_FITNESS", text: "Health, Wellness and Fitness" },
    { value: "HIGHER_EDUCATION", text: "Higher Education" },
    { value: "HOSPITAL_HEALTH_CARE", text: "Hospital & Health Care" },
    { value: "HOSPITALITY", text: "Hospitality" },
    { value: "HUMAN_RESOURCES", text: "Human Resources" },
    { value: "IMPORT_AND_EXPORT", text: "Import and Export" },
    { value: "INDIVIDUAL_FAMILY_SERVICES", text: "Individual & Family Services" },
    { value: "INDUSTRIAL_AUTOMATION", text: "Industrial Automation" },
    { value: "INFORMATION_SERVICES", text: "Information Services" },
    { value: "INFORMATION_TECHNOLOGY_AND_SERVICES", text: "Information Technology and Services" },
    { value: "INSURANCE", text: "Insurance" },
    { value: "INTERNATIONAL_AFFAIRS", text: "International Affairs" },
    { value: "INTERNATIONAL_TRADE_AND_DEVELOPMENT", text: "International Trade and Development" },
    { value: "INTERNET", text: "Internet" },
    { value: "INVESTMENT_BANKING", text: "Investment Banking" },
    { value: "INVESTMENT_MANAGEMENT", text: "Investment Management" },
    { value: "JUDICIARY", text: "Judiciary" },
    { value: "LAW_ENFORCEMENT", text: "Law Enforcement" },
    { value: "LAW_PRACTICE", text: "Law Practice" },
    { value: "LEGAL_SERVICES", text: "Legal Services" },
    { value: "LEGISLATIVE_OFFICE", text: "Legislative Office" },
    { value: "LEISURE_TRAVEL_TOURISM", text: "Leisure, Travel & Tourism" },
    { value: "LIBRARIES", text: "Libraries" },
    { value: "LOGISTICS_AND_SUPPLY_CHAIN", text: "Logistics and Supply Chain" },
    { value: "LUXURY_GOODS_JEWELRY", text: "Luxury Goods & Jewelry" },
    { value: "MACHINERY", text: "Machinery" },
    { value: "MANAGEMENT_CONSULTING", text: "Management Consulting" },
    { value: "MARITIME", text: "Maritime" },
    { value: "MARKET_RESEARCH", text: "Market Research" },
    { value: "MARKETING_AND_ADVERTISING", text: "Marketing and Advertising" },
    { value: "MECHANICAL_OR_INDUSTRIAL_ENGINEERING", text: "Mechanical or Industrial Engineering" },
    { value: "MEDIA_PRODUCTION", text: "Media Production" },
    { value: "MEDICAL_DEVICES", text: "Medical Devices" },
    { value: "MEDICAL_PRACTICE", text: "Medical Practice" },
    { value: "MENTAL_HEALTH_CARE", text: "Mental Health Care" },
    { value: "MILITARY", text: "Military" },
    { value: "MINING_METALS", text: "Mining & Metals" },
    { value: "MOTION_PICTURES_AND_FILM", text: "Motion Pictures and Film" },
    { value: "MUSEUMS_AND_INSTITUTIONS", text: "Museums and Institutions" },
    { value: "MUSIC", text: "Music" },
    { value: "NANOTECHNOLOGY", text: "Nanotechnology" },
    { value: "NEWSPAPERS", text: "Newspapers" },
    { value: "NON_PROFIT_ORGANIZATION_MANAGEMENT", text: "Non-Profit Organization Management" },
    { value: "OIL_ENERGY", text: "Oil & Energy" },
    { value: "ONLINE_MEDIA", text: "Online Media" },
    { value: "OUTSOURCING_OFFSHORING", text: "Outsourcing/Offshoring" },
    { value: "PACKAGE_FREIGHT_DELIVERY", text: "Package/Freight Delivery" },
    { value: "PACKAGING_AND_CONTAINERS", text: "Packaging and Containers" },
    { value: "PAPER_FOREST_PRODUCTS", text: "Paper & Forest Products" },
    { value: "PERFORMING_ARTS", text: "Performing Arts" },
    { value: "PHARMACEUTICALS", text: "Pharmaceuticals" },
    { value: "PHILANTHROPY", text: "Philanthropy" },
    { value: "PHOTOGRAPHY", text: "Photography" },
    { value: "PLASTICS", text: "Plastics" },
    { value: "POLITICAL_ORGANIZATION", text: "Political Organization" },
    { value: "PRIMARY_SECONDARY_EDUCATION", text: "Primary/Secondary Education" },
    { value: "PRINTING", text: "Printing" },
    { value: "PROFESSIONAL_TRAINING_COACHING", text: "Professional Training & Coaching" },
    { value: "PROGRAM_DEVELOPMENT", text: "Program Development" },
    { value: "PUBLIC_POLICY", text: "Public Policy" },
    { value: "PUBLIC_RELATIONS_AND_COMMUNICATIONS", text: "Public Relations and Communications" },
    { value: "PUBLIC_SAFETY", text: "Public Safety" },
    { value: "PUBLISHING", text: "Publishing" },
    { value: "RAILROAD_MANUFACTURE", text: "Railroad Manufacture" },
    { value: "RANCHING", text: "Ranching" },
    { value: "REAL_ESTATE", text: "Real Estate" },
    { value: "RECREATIONAL_FACILITIES_AND_SERVICES", text: "Recreational Facilities and Services" },
    { value: "RELIGIOUS_INSTITUTIONS", text: "Religious Institutions" },
    { value: "Renderfarm", text: "Renderfarm" },
    { value: "RENEWABLES_ENVIRONMENT", text: "Renewables & Environment" },
    { value: "RESEARCH", text: "Research" },
    { value: "RESTAURANTS", text: "Restaurants" },
    { value: "RETAIL", text: "Retail" },
    { value: "SECURITY_AND_INVESTIGATIONS", text: "Security and Investigations" },
    { value: "SEMICONDUCTORS", text: "Semiconductors" },
    { value: "SHIPBUILDING", text: "Shipbuilding" },
    { value: "SPORTING_GOODS", text: "Sporting Goods" },
    { value: "SPORTS", text: "Sports" },
    { value: "STAFFING_AND_RECRUITING", text: "Staffing and Recruiting" },
    { value: "SUPERMARKETS", text: "Supermarkets" },
    { value: "TELECOMMUNICATIONS", text: "Telecommunications" },
    { value: "TEXTILES", text: "Textiles" },
    { value: "THINK_TANKS", text: "Think Tanks" },
    { value: "TOBACCO", text: "Tobacco" },
    { value: "TRANSLATION_AND_LOCALIZATION", text: "Translation and Localization" },
    { value: "TRANSPORTATION_TRUCKING_RAILROAD", text: "Transportation/Trucking/Railroad" },
    { value: "UTILITIES", text: "Utilities" },
    { value: "VENTURE_CAPITAL_PRIVATE_EQUITY", text: "Venture Capital & Private Equity" },
    { value: "VETERINARY", text: "Veterinary" },
    { value: "WAREHOUSING", text: "Warehousing" },
    { value: "WHOLESALE", text: "Wholesale" },
    { value: "WINE_AND_SPIRITS", text: "Wine and Spirits" },
    { value: "WIRELESS", text: "Wireless" },
    { value: "WRITING_AND_EDITING", text: "Writing and Editing" }
];

export const ContractType = [
    {
        value: "Reserved Capacity",
        label: "Reserved Capacity"
    }, {
        value: "1 Month Rolling",
        label: "1 Month Rolling"
    }
]

export const GPUType = [
    {
        value: "NVIDIAH100 HGX",
        label: "Nvidia H100 HGX"
    }, {
        value: "NVIDIAL40S",
        label: "Nvidia L40S"
    }, {
        value: "NVIDIAH200NVL",
        label: "Nvidia H200 NVL"
    }
]

export const usecaseOptions = [
    { value: "Deep Learning Inference", text: "Deep Learning Inference" },
    { value: "Deep Learning Training", text: "Deep Learning Training" },
    { value: "Graphics and Simulation Workflows", text: "Graphics and Simulation Workflows" },
    { value: "High-Performance Data Analytics", text: "High-Performance Data Analytics" },
    { value: "Model Training for AI", text: "Model Training for AI" },
    { value: "Rendering, CAD, CAE", text: "Rendering, CAD, CAE" },
    { value: "Foundational LLM Training", text: "Foundational LLM Training" },
    { value: "Other", text: "Other" }
];