import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import * as Fa from 'react-icons/fa';
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Footer } from "../../section/footer";
import { Form } from "../../section/form";
import { Hero } from "../../section/hero";
import { Navbar } from "../../section/navbar";
import { Services } from "../../section/services";
import { Special } from "../../section/special";
import { Why } from "../../section/why";
import { WhatsappContainer, WhatsappWrapper } from "./style";

const HomeWrapper = styled.div`
    position: relative;
`;

export const Home = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Homepage" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HomeWrapper>
            <Navbar />
            <Hero />
            <Services />
            <Special />
            <Why />
            <Form />
            <Footer />
            <WhatsappWrapper>
                <WhatsappContainer>
                    <Fa.FaWhatsapp size={30} color="#fff" onClick={() => {
                        ReactGA.send({ hitType: "whatsapp", page: location.pathname + location.search, title: "Whatsapp" });
                        window.open('https://wa.me/6281289996036?text=Hi KCP, I\'d like to know more on your services. Thanks')
                    }} />
                </WhatsappContainer>
            </WhatsappWrapper>
        </HomeWrapper>
    );
}