import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { SectionContainer, SectionWrapper } from "../../pages/home/style";
import { ContractType, GPUType, industryOptions, usecaseOptions } from "./data";
import { FormContainerLeft, FormFlex, FormGroup, FormRight, FormWrapper } from "./style";

export const Form = () => {
    const { t } = useTranslation();

    const recaptcha = useRef();
    const [inputType, setInputType] = useState('text');
    const [endInputType, setEndInputType] = useState('text');
    const [data, setData] = useState({});


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleFocus = (type) => {
        if (type === "start") {
            setInputType('date');
        } else {
            setEndInputType('date')
        }
    };


    const submitForm = async (e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue();
        console.log(captchaValue);
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!')
        } else {
            const endpoint = 'https://formspree.io/f/xwkgdbar';

            try {
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                if (response.ok) {
                    // Handle success, e.g., show a success message or redirect
                    console.log('Form submitted successfully!');
                    window.location = "/";
                } else {
                    // Handle errors, e.g., show an error message
                    console.error('Form submission failed:', response.statusText);
                }
            } catch (error) {
                console.error('Form submission error:', error);
            }
            alert('Form submission successful!')
        }
    }

    return (
        <SectionWrapper>
            <SectionContainer>
                <FormWrapper>
                    <FormContainerLeft>
                        <h1>{t('contact.title')}</h1>
                        <h2>{t('contact.headline')}</h2>
                        <p>{t('contact.subheadline')}</p>
                    </FormContainerLeft>
                    <FormRight onSubmit={submitForm}>
                        <h1>{t('contact.form.contact_heading')}</h1>
                        <FormFlex>
                            <FormGroup>
                                <input name="full_name" placeholder={t('contact.form.full_name')} required type="text" onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <input name="email" placeholder={t('contact.form.email')} required type="email" onChange={handleInputChange} />
                            </FormGroup>
                        </FormFlex>
                        <FormFlex>
                            <FormGroup>
                                <input name="phone_number" placeholder={t('contact.form.phone_number')} required onChange={handleInputChange} />
                            </FormGroup>
                        </FormFlex>
                        <FormFlex>
                            <FormGroup>
                                <input name="job_title" placeholder={t('contact.form.job_title')} required onChange={handleInputChange} />
                            </FormGroup>
                        </FormFlex>
                        <h1>{t('contact.form.company_heading')}</h1>
                        <FormFlex>
                            <FormGroup>
                                <input name="company_name" placeholder={t('contact.form.company_name')} required onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <input name="company_url" placeholder={t('contact.form.company_url')} required onChange={handleInputChange} />
                            </FormGroup>
                        </FormFlex>
                        <FormFlex>
                            <FormGroup>
                                <input name="country" placeholder={t('contact.form.country')} required onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <select name="industry" placeholder={t('contact.form.industry')} onChange={handleInputChange}>
                                    <option selected disabled value={""}>{t('contact.form.industry')}</option>
                                    {industryOptions.map((item, index) => <option key={index} value={item.value}>
                                        {item.text}
                                    </option>)}
                                </select>
                            </FormGroup>
                        </FormFlex>
                        <h1>{t('contact.form.request_heading')}</h1>
                        <FormFlex>
                            <FormGroup>
                                <select name="contract" placeholder={t('contact.form.contract_type')} onChange={handleInputChange}>
                                    <option selected disabled value={""}>{t('contact.form.contract_type')}</option>
                                    {ContractType.map((item, index) => <option key={index} value={item.value}>
                                        {item.label}
                                    </option>)}
                                </select>
                            </FormGroup>
                            <FormGroup>
                                <select name="gpu" placeholder={t('contact.form.gpu_type')} onChange={handleInputChange}>
                                    <option selected disabled value={""}>{t('contact.form.gpu_type')}</option>
                                    {GPUType.map((item, index) => <option key={index} value={item.value}>
                                        {item.label}
                                    </option>)}
                                </select>
                            </FormGroup>
                            <FormGroup>
                                <input name="amount" placeholder={t('contact.form.country')} required onChange={handleInputChange} />
                            </FormGroup>
                        </FormFlex>

                        <FormFlex>
                            <FormGroup>
                                <input onChange={handleInputChange} name="start" type={inputType} onBlur={() => setInputType("text")} onFocus={() => handleFocus("start")} placeholder={t('contact.form.start')} required />
                            </FormGroup>
                            <FormGroup>
                                <input onChange={handleInputChange} name="end" type={endInputType} onBlur={() => setEndInputType("text")} onFocus={() => handleFocus("end")} placeholder={t('contact.form.end')} required />
                            </FormGroup>
                        </FormFlex>

                        <FormFlex>
                            <FormGroup>
                                <select name="usecase" onChange={handleInputChange} placeholder={t('contact.form.usecase')}>
                                    <option selected disabled value={""}>{t('contact.form.usecase')}</option>
                                    {usecaseOptions.map((item, index) => <option key={index} value={item.value}>
                                        {item.text}
                                    </option>)}
                                </select>
                            </FormGroup>
                        </FormFlex>
                        <textarea onChange={handleInputChange} name="detail" placeholder={t('contact.form.needs')} required />
                        <span>By clicking submit below, you consent to allow KCP AI Cloud to store and process the personal information submitted above to provide you the content requested.</span>
                        <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} />
                        <button type="submit">
                            {t('contact.form.button')}
                        </button>
                    </FormRight>
                </FormWrapper>
            </SectionContainer>
        </SectionWrapper>
    )
}