import styled from "styled-components";

export const SectionWrapper = styled.div`
    max-width: 1180px;
    margin: 2.5rem auto 3rem;

    @media screen and (min-width: 1024px) {
        margin: 5rem auto 1rem;
    }
`;

export const SectionContainer = styled.div`
    padding: 1rem;
`;

export const WhatsappWrapper = styled.div`
    position: fixed;
    right: 20px;
    bottom: 20px;
`;

export const WhatsappContainer = styled.div`
    padding: 8px;
    background-color: #25D366;
    border-radius: 8px;
    cursor: pointer;
`;