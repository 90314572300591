import styled from "styled-components";

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
    }
`;

export const FormContainerLeft = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    
    & > h1 {
        text-transform: uppercase;
        color: #2a45cf;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 2px;
    }

    & > h2 {
        font-size: 2rem;
        margin: 1.1rem 0 8px;
    }
    & > p {
        font-size: 17px;
        line-height: 1.5;
    }

    @media screen and (min-width: 1024px) {
        & > p {
            max-width: 75%;
        }
    }
`;

export const FormRight = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    flex: 1;

    & > span {
        font-size: 11px;
    }

    & > h1 {
        font-size: 21px;
    }

    & > textarea {
        height: 100px;
        padding: 10px 20px;
        border-radius: 8px;
        font-size: 16px;
        border: 1px solid #eee;
    }

    & > button {
        cursor: pointer;
        position: relative;
        align-self: flex-start;
        font-weight:600;
        margin-top: 1.5rem;
        font-size: 16px;
        overflow: hidden;
        text-align:center;
        border-radius:5px;
        padding: 14px 34px;
        display:inline-block;
        color: #fff;
        background-color: #2a45cf;
        transition: .6s ease;
        outline: none;
        border: 0;
    }

    @media screen and (min-width: 1024px) {
        margin-top:0 ;
        gap: 1.5rem;
    }
`;

export const FormFlex = styled.div`
    display: flex;
    gap: 8px;

    @media screen and (min-width: 1024px) {
        gap: 1rem;
    }
`

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > label {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
    }

     & > input, & > select {
        height: 48px;
        padding: 0 20px;
        border-radius: 8px;
        font-size: 16px;
        border: 1px solid #eee;
        width: 100%;
     }

`;