import styled from "styled-components";

export const WhyWrapper = styled.div`
    margin-top: 5rem;
`;

export const WhyContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 4rem;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

export const WhyContainerBottom = styled.div`

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 4rem;

    @media screen and (min-width: 1024px) {
        flex-direction: row-reverse;
    }
`;

export const WhyLeftContainer = styled.div`
    @media screen and (max-width: 720px) { 
        & > img {
            width: 100%;
        }
    }
`;

export const WhyRightContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > h1 {
        font-size: 2rem;
    }

    & > p {
        line-height: 1.5;
        font-size: 1.1rem;
        margin-top: 1rem;
    }

    @media screen and (min-width: 1024px) {
        & > h1 {
            font-size: 3rem;
        }

        & > p {
            font-size: 1.3rem;
        }
    }
`;

export const WhyButton = styled.a`
    text-decoration: none;
    cursor: pointer;
    position: relative;
    align-self: flex-start;
	font-weight:600;
    margin-top: 1.5rem;
    font-size: 16px;
	overflow: hidden;
	text-align:center;
	border-radius:5px;
	padding:18px 34px;
	display:inline-block;
    color: #fff;
	background-color: #2a45cf;
    transition: .6s ease;

    &:hover {
        transform: scale(1.01);
    }
`;