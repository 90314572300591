import { createGlobalStyle } from "styled-components";
import CircularBlackWoof from '../assets/fonts/circular/CircularStd-Black.woff';
import CircularBlackWoof2 from '../assets/fonts/circular/CircularStd-Black.woff2';
import CircularBoldWoof from '../assets/fonts/circular/CircularStd-Bold.woff';
import CircularBoldWoof2 from '../assets/fonts/circular/CircularStd-Bold.woff2';
import CircularBookWoof from '../assets/fonts/circular/CircularStd-Book.woff';
import CircularBookWoof2 from '../assets/fonts/circular/CircularStd-Book.woff2';
import CircularMediumWoof from '../assets/fonts/circular/CircularStd-Medium.woff';
import CircularMediumWoof2 from '../assets/fonts/circular/CircularStd-Medium.woff2';

export const GlobalStyles = createGlobalStyle`
    @font-face {
    font-family: 'Circular Std';
    src: url('${CircularBoldWoof2}') format('woff2'),
        url('${CircularBoldWoof}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    }

    @font-face {
        font-family: 'Circular Std';
        src: url('${CircularMediumWoof2}') format('woff2'),
            url('${CircularMediumWoof}') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Circular Std';
        src: url('${CircularBlackWoof2}') format('woff2'),
            url('${CircularBlackWoof}') format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Circular Std';
        src: url('${CircularBookWoof2}') format('woff2'),
            url('${CircularBookWoof}') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    * {
        font-family: 'Inter', sans-serif;
        margin: 0;
        padding: 0;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    h1, h2, h3, h4 {
        margin: 0;
        padding: 0;
        color: rgba(0,0,0,.98);
        font-family: 'Circular Std', sans-serif;
    }

    html {
        scroll-behavior: smooth
    }
`;
