import React from "react";
import { useTranslation } from "react-i18next";
import * as Pi from 'react-icons/pi';
import logo from '../../assets/image/logo.png';
import i18n from "../../i18n";
import { LanguageFlex, MobileLanguageWrapper, NavbarCTA, NavbarContainer, NavbarFlex, NavbarMenu, NavbarWrapper } from "./style";

export const Navbar = () => {
    const { t } = useTranslation();

    const changeLanguageHandler = (lang) => {
        console.log(lang);
        if (lang.toLowerCase() === 'english') {
            i18n.changeLanguage('en');
        } else if (lang.toLowerCase() === 'bahasa') {
            i18n.changeLanguage('id');
        }
    };

    return (
        <NavbarWrapper>
            <NavbarContainer>
                <NavbarFlex>
                    <a href="/">
                        <img src={logo} alt="logo" width="100%" />
                    </a>
                    <NavbarMenu>
                        <a href="#services">{t('navbar.services')}</a>
                        <a href="#benefits">{t('navbar.benefits')}</a>
                        <a href="#contacts">{t('navbar.contact')}</a>
                    </NavbarMenu>
                    <NavbarMenu>
                        <NavbarCTA href="mailto:inquiry@kcpaicloud.com?subject=Hi, I'm interested!" target="_blank">
                            <span>{t('navbar.button')}</span>
                        </NavbarCTA>
                        <LanguageFlex>
                            <Pi.PiGlobeSimpleLight size={20} />
                            <select onChange={({ currentTarget }) => changeLanguageHandler(currentTarget.value)}>
                                <option value={"english"}>EN</option>
                                <option value={"bahasa"}>ID</option>
                            </select>
                        </LanguageFlex>
                    </NavbarMenu>
                    <MobileLanguageWrapper>
                        <LanguageFlex>
                            <Pi.PiGlobeSimpleLight size={20} />
                            <select onChange={({ currentTarget }) => changeLanguageHandler(currentTarget.value)}>
                                <option value={"english"}>EN</option>
                                <option value={"bahasa"}>ID</option>
                            </select>
                        </LanguageFlex>
                    </MobileLanguageWrapper>
                </NavbarFlex>
            </NavbarContainer>
        </NavbarWrapper>
    )
}