import React from "react";
import { useTranslation } from "react-i18next";
import img from '../../assets/image/why1.png';
import why2 from '../../assets/image/why2.png';
import { SectionContainer, SectionWrapper } from "../../pages/home/style";
import { HeroWrapper } from "../hero/style";
import { WhyButton, WhyContainer, WhyContainerBottom, WhyLeftContainer, WhyRightContainer, WhyWrapper } from "./style";

export const Why = () => {
    const { t } = useTranslation();

    return (
        <HeroWrapper>
            <SectionWrapper style={{ marginTop: "1rem" }} id="benefits">
                <SectionContainer>
                    <WhyWrapper>
                        <WhyContainer>
                            <WhyLeftContainer data-aos="fade-right">
                                <img src={img} alt="why1" />
                            </WhyLeftContainer>
                            <WhyRightContainer data-aos="fade-left">
                                <h1>{t('why.top.headline')}</h1>
                                <p>{t('why.top.subheadline')}</p>
                                <WhyButton href="mailto:inquiry@kcpaicloud.com?subject=Hi, I'm interested!">
                                    <span>{t('why.top.button')}</span>
                                </WhyButton>
                            </WhyRightContainer>
                        </WhyContainer>
                    </WhyWrapper>
                    <WhyWrapper>
                        <WhyContainerBottom>
                            <WhyLeftContainer data-aos="fade-left">
                                <img src={why2} alt="why1" />
                            </WhyLeftContainer>
                            <WhyRightContainer data-aos="fade-right">
                                <h1>{t('why.bottom.headline')}</h1>
                                <p>{t('why.bottom.subheadline')}</p>
                                <WhyButton href="mailto:inquiry@kcpaicloud.com?subject=Hi, I'm interested!">
                                    <span>{t('why.bottom.button')}</span>
                                </WhyButton>
                            </WhyRightContainer>
                        </WhyContainerBottom>
                    </WhyWrapper>
                </SectionContainer>
            </SectionWrapper>
        </HeroWrapper>
    )
}